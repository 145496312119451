.map {
  height: 70vh;
  max-width: 444px;
}
@media screen and (max-width: 360px) {
  .map {
    height: 65vh;
  }
}
@media screen and (max-width: 320px) {
  .map {
    height: 58vh;
  }
}
