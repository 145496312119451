@import url(https://fonts.googleapis.com/css?family=Roboto:400, 500, 700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.map {
  height: 120px;
  border-radius: 8px;
  width: 100%;
}

/*
 * Copyright 2016 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * CSS for FirebaseUI Javascript widget.
 * All selectors should begin with a "firebaseui-" prefix to avoid conflicts.
 */
.firebaseui-container {
  background-color: #fff;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  direction: ltr;
  font: 16px Roboto, arial, sans-serif;
  margin: 0 auto;
  max-width: 360px;
  /** This is needed for the invisible reCAPTCHA badge to show. */
  overflow: visible;
  position: relative;
  text-align: left;
  width: 100%;
}

.firebaseui-container.mdl-card {
  /** This is needed for the invisible reCAPTCHA badge to show. */
  overflow: visible;
}

.firebaseui-card-header {
  padding: 24px 24px 0 24px;
}

.firebaseui-card-content {
  padding: 0 24px;
}

.firebaseui-card-footer {
  padding: 0 24px;
}

.firebaseui-card-actions {
  box-sizing: border-box;
  display: table;
  font-size: 14px;
  padding: 8px 24px 24px 24px;
  text-align: left;
  width: 100%;
}

.firebaseui-form-links {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

.firebaseui-form-actions {
  display: table-cell;
  text-align: right;
  white-space: nowrap;
  width: 100%;
}

.firebaseui-title,
.firebaseui-subtitle {
  color: rgba(0, 0, 0, 0.87);
  direction: ltr;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  padding: 0;
  text-align: left;
}

.firebaseui-title {
  padding-bottom: 16px;
}

.firebaseui-subtitle {
  margin: 16px 0;
}

.firebaseui-text {
  color: rgba(0, 0, 0, 0.87);
  direction: ltr;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}

.firebaseui-id-page-password-recovery-email-sent p.firebaseui-text {
  margin: 16px 0;
}

.firebaseui-text-emphasis {
  font-weight: 700;
}

.firebaseui-error {
  color: #dd2c00;
  direction: ltr;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  text-align: left;
}

.firebaseui-text-input-error {
  /* Set a negative margin, as MDL text inputs have a large bottom margin. */
  margin: -16px 0 16px;
}

.firebaseui-error-wrapper {
  min-height: 16px;
}

.firebaseui-list-item {
  direction: ltr;
  margin: 0;
  padding: 0;
  text-align: left;
}

.firebaseui-hidden {
  display: none;
}

.firebaseui-relative-wrapper {
  position: relative;
}

.firebaseui-label {
  color: rgba(0, 0, 0, 0.54);
  direction: ltr;
  font-size: 16px;
  text-align: left;
}

/* Override MDL floating text field label color. */
.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty .mdl-textfield__label {
  color: #757575;
}

.firebaseui-input,
.firebaseui-input-invalid {
  border-radius: 0; /** iOS puts rounded corners on text inputs. */
  color: rgba(0, 0, 0, 0.87);
  direction: ltr;
  font-size: 16px;
  width: 100%;
}

input.firebaseui-input,
input.firebaseui-input-invalid {
  direction: ltr;
  text-align: left;
}

.firebaseui-input-invalid {
  border-color: #dd2c00;
}

.firebaseui-textfield {
  width: 100%;
}

/* Override MDL's error handling with our own.  */
.firebaseui-textfield.mdl-textfield .firebaseui-input {
  border-color: rgba(0, 0, 0, 0.12);
}

.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  background-color: #3f51b5;
}

.firebaseui-textfield-invalid.mdl-textfield .firebaseui-input {
  border-color: #dd2c00;
}

.firebaseui-textfield-invalid.mdl-textfield .firebaseui-label::after {
  background-color: #dd2c00;
}

.firebaseui-button {
  display: inline-block;
  height: 36px;
  margin-left: 8px;
  min-width: 88px;
}

.firebaseui-link {
  color: #4285f4;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
}

.firebaseui-link:hover {
  text-decoration: underline;
}

.firebaseui-indent {
  margin-left: 1em;
}

.firebaseui-tos {
  color: #757575;
  direction: ltr;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 24px;
  margin-top: 0;
  text-align: left;
}

.firebaseui-provider-sign-in-footer > .firebaseui-tos {
  text-align: center;
}

.firebaseui-tos-list {
  list-style: none;
  text-align: right;
}

.firebaseui-inline-list-item {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.firebaseui-page-provider-sign-in {
  background: inherit;
}

.firebaseui-idp-list {
  list-style: none;
  margin: 1em 0;
  padding: 0;
}

.firebaseui-idp-button {
  direction: ltr;
  font-weight: 500;
  height: auto;
  line-height: normal;
  max-width: 220px;
  min-height: 40px;
  padding: 8px 16px;
  text-align: left;
  width: 100%;
}

.firebaseui-idp-list > .firebaseui-list-item {
  margin-bottom: 15px;
  text-align: center;
}

.firebaseui-idp-icon-wrapper {
  margin: 0;
  display: table-cell;
  vertical-align: middle;
}

.firebaseui-idp-icon {
  border: none;
  display: inline-block;
  height: 18px;
  vertical-align: middle;
  width: 18px;
}

.firebaseui-idp-favicon {
  border: none;
  display: inline-block;
  height: 14px;
  margin-right: 5px;
  vertical-align: middle;
  width: 14px;
}

.firebaseui-idp-text {
  color: #fff;
  display: table-cell;
  font-size: 14px;
  padding-left: 15px;
  text-transform: none;
  vertical-align: middle;
}

.firebaseui-idp-text.firebaseui-idp-text-long {
  display: table-cell;
}

.firebaseui-idp-text.firebaseui-idp-text-short {
  display: none;
}

/* 220px for button + 2x24 for margin = 268px */
@media (max-width: 268px) {
  .firebaseui-idp-text.firebaseui-idp-text-long {
    display: none;
  }

  .firebaseui-idp-text.firebaseui-idp-text-short {
    display: table-cell;
  }
}

/* Resize reCAPTCHA container on narrow mobile devices. */
@media (max-width: 320px) {
  .firebaseui-recaptcha-container > div > div {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.firebaseui-idp-password,
.firebaseui-idp-password:hover,
.mdl-button.firebaseui-idp-password:active,
.mdl-button.firebaseui-idp-password:focus {
  background-color: #db4437;
}

.firebaseui-idp-phone,
.firebaseui-idp-phone:hover,
.mdl-button.firebaseui-idp-phone:active,
.mdl-button.firebaseui-idp-phone:focus {
  background-color: #02bd7e;
}

.firebaseui-idp-google,
.firebaseui-idp-google:hover,
.mdl-button.firebaseui-idp-google:active,
.mdl-button.firebaseui-idp-google:focus {
  background-color: #ffffff;
}

.firebaseui-idp-google > .firebaseui-idp-text {
  color: #757575;
}

.firebaseui-idp-github,
.firebaseui-idp-github:hover,
.mdl-button.firebaseui-idp-github:active,
.mdl-button.firebaseui-idp-github:focus {
  background-color: #333333;
}

.firebaseui-idp-facebook,
.firebaseui-idp-facebook:hover,
.mdl-button.firebaseui-idp-facebook:active,
.mdl-button.firebaseui-idp-facebook:focus {
  background-color: #3b5998;
}

.firebaseui-idp-twitter,
.firebaseui-idp-twitter:hover,
.mdl-button.firebaseui-idp-twitter:active,
.mdl-button.firebaseui-idp-twitter:focus {
  background-color: #55acee;
}

.firebaseui-idp-anonymous,
.firebaseui-idp-anonymous:hover,
.mdl-button.firebaseui-idp-anonymous:active,
.mdl-button.firebaseui-idp-anonymous:focus {
  background-color: #f4b400;
}

.firebaseui-info-bar {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  left: 10%;
  padding: 8px 16px;
  position: absolute;
  right: 10%;
  text-align: center;
  top: 0;
}

.firebaseui-info-bar-message {
  font-size: 12px;
  margin: 0;
}

.firebaseui-dialog {
  box-sizing: border-box;
  /* We need to set these properties again since dialogs are not contained in
   * the main FirebaseUI container. */
  color: rgba(0, 0, 0, 0.87);
  font: 16px Roboto, arial, sans-serif;
  /* If we do not set height here, the default Safari dialog settings make the
   * dialog too tall. */
  height: auto;
  padding: 24px;
  text-align: left;
}

.firebaseui-dialog-icon-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.firebaseui-dialog-icon {
  float: left;
  height: 40px;
  margin-right: 24px;
  width: 40px;
}

.firebaseui-progress-dialog-message {
  display: table-cell;
  font-size: 16px;
  font-weight: 400;
  min-height: 40px;
  vertical-align: middle;
}

.firebaseui-progress-dialog-loading-icon {
  height: 28px;
  margin: 6px 30px 6px 6px;
  width: 28px;
}

.firebaseui-icon-done {
  background-image: url('https://www.gstatic.com/images/icons/material/system/2x/done_googgreen_36dp.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 36px 36px;
}

.firebaseui-phone-number {
  display: flex;
}

.firebaseui-country-selector {
  /* TODO: Use 2x for HD displays. */
  background-image: url('https://www.gstatic.com/images/icons/material/system/1x/arrow_drop_down_grey600_18dp.png');
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 18px auto;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  flex-shrink: 0;
  font-size: 16px;
  font-weight: normal;
  height: initial;
  line-height: normal;
  margin: 20px 24px 20px 0;
  padding: 4px 20px 4px 0;
  width: 90px;
}

.firebaseui-country-selector-flag {
  display: inline-block;
  margin-right: 1ex;
}

.firebaseui-flag {
  background-image: url('https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/flags_sprite_2x.png');
  background-size: 100% auto;
  /* Set a drop shadow so white flags are more distinct. */
  -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.54));
          filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.54));
  height: 14px;
  width: 24px;
}

.firebaseui-list-box-dialog {
  max-height: 90%;
  overflow: auto;
  padding: 8px 0 0 0;
}

.firebaseui-list-box-actions {
  padding-bottom: 8px;
}

.firebaseui-list-box-icon-wrapper {
  display: table-cell;
  padding-right: 24px;
  vertical-align: top;
}

.firebaseui-list-box-label-wrapper {
  display: table-cell;
  vertical-align: top;
}

.firebaseui-list-box-dialog-button {
  color: rgba(0, 0, 0, 0.87);
  direction: ltr;
  font-size: 16px;
  font-weight: normal;
  height: initial;
  line-height: normal;
  min-height: 48px;
  padding: 14px 24px;
  text-align: left;
  text-transform: none;
  width: 100%;
}

.firebaseui-phone-number-error {
  /* Align this with the national number input. Add a margin of
   * button width (90px) + button margin (24px). */
  margin-left: 114px;
}

.mdl-progress.firebaseui-busy-indicator {
  height: 2px;
  left: 0;
  position: absolute;
  top: 55px;
  width: 100%;
}

.mdl-spinner.firebaseui-busy-indicator {
  height: 56px;
  left: 0px;
  margin: auto;
  position: absolute;
  right: 0px;
  top: 30%;
  width: 56px;
}

.firebaseui-callback-indicator-container .firebaseui-busy-indicator {
  top: 0px;
}

.firebaseui-callback-indicator-container {
  height: 120px;
}

.firebaseui-new-password-component {
  display: inline-block;
  position: relative;
  width: 100%;
}

.firebaseui-input-floating-button {
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  position: absolute;
  right: 0;
  top: 20px;
  width: 24px;
}

.firebaseui-input-toggle-on {
  background-image: url('https://www.gstatic.com/images/icons/material/system/1x/visibility_black_24dp.png');
}

.firebaseui-input-toggle-off {
  background-image: url('https://www.gstatic.com/images/icons/material/system/1x/visibility_off_black_24dp.png');
}

.firebaseui-input-toggle-focus {
  opacity: 0.87;
}

.firebaseui-input-toggle-blur {
  opacity: 0.38;
}

.firebaseui-recaptcha-wrapper {
  display: table;
  margin: 0 auto;
  padding-bottom: 8px;
}

.firebaseui-recaptcha-container {
  display: table-cell;
}

.firebaseui-recaptcha-error-wrapper {
  caption-side: bottom;
  display: table-caption;
}

.firebaseui-change-phone-number-link {
  display: block;
}

.firebaseui-resend-container {
  direction: ltr;
  margin: 20px 0;
  text-align: center;
}

.firebaseui-id-resend-countdown {
  color: rgba(0, 0, 0, 0.38);
}

/**
 * This div holds the invisible reCAPTCHA badge. The float property is needed
 * as it messes up the cancel and submit buttons to align vertically when they
 * should horizontally.
 */
.firebaseui-id-page-phone-sign-in-start .firebaseui-form-actions div {
  float: left;
}

@media (max-width: 480px) {
  .firebaseui-container {
    box-shadow: none;
    max-width: none;
    width: 100%;
  }

  .firebaseui-card-header {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 16px;
    padding: 16px 24px 0 24px;
  }

  .firebaseui-title {
    padding-bottom: 16px;
  }

  .firebaseui-card-actions {
    padding-right: 24px;
  }

  .firebaseui-busy-indicator {
    top: 0px;
  }
}

/*
 * Bootstrap applies styles to all <label> elements, so override those styles.
 */
.mdl-textfield__label {
  font-weight: normal;
  margin-bottom: 0;
}

.firebaseui-id-page-blank {
  background: inherit;
  height: 64px;
}

.firebaseui-email-sent {
  background-image: url('https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/success_status.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 64px 64px;
  height: 64px;
  /* margin-top: 16px; */
  text-align: center;
}

.firebaseui-text-justify {
  text-align: justify;
}

/*
 * Copyright 2016 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * CSS imports for FirebaseUI Javascript widget.
 */

/**
 * Copyright 2016 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* Bundles together required MDL CSS components. */
/* @import '../../../node_modules/material-design-lite/src/variables';
@import '../../../node_modules/material-design-lite/src/mixins';

@import '../../../node_modules/material-design-lite/src/button/button';
@import '../../../node_modules/material-design-lite/src/card/card';
@import '../../../node_modules/material-design-lite/src/dialog/dialog';
@import '../../../node_modules/material-design-lite/src/progress/progress';
@import '../../../node_modules/material-design-lite/src/shadow/shadow';
@import '../../../node_modules/material-design-lite/src/spinner/spinner';
@import '../../../node_modules/material-design-lite/src/textfield/textfield'; */

.title {
  color: #25282b;
  font-size: 14px;
  margin-bottom: 15px;
  font-weight: bold;
  width: 100%;
  min-height: 38px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 18px;
}
@media screen and (max-width: 375px) {
  .title {
    font-size: 12px;
  }
}

.title {
  color: #252525;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
  width: 100%;
  height: 35px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media screen and (max-width: 375px) {
  .title {
    font-size: 12px;
  }
}

.map {
  height: 70vh;
  max-width: 444px;
}
@media screen and (max-width: 360px) {
  .map {
    height: 65vh;
  }
}
@media screen and (max-width: 320px) {
  .map {
    height: 58vh;
  }
}

